import LRTService from 'src/services/longRunningTask';
import { actions, statuses } from 'src/config/long-running-task-config.js';
import { notification } from 'ant-design-vue';
import { showNotification } from 'src/components/shared/Helpers/showNotification';
import { InfoCircleOutlined } from '@ant-design/icons-vue';

export default {
  data: () => ({
    longRunningTaskInterval: null,
    taskUpdateNotification: false,
    isTaskBeingUpdated: false,
  }),

  components: { InfoCircleOutlined },

  beforeUnmount() {
    this.clearLRTInterval();
    this.removeListener?.();
  },

  methods: {
    setLongRunningTaskInterval() {
      this.fetchLongRunningTasks(this.selectedTask);
      this.longRunningTaskInterval = setInterval(
        this.fetchLongRunningTasks,
        2000,
        this.selectedTask
      );
    },

    async fetchLongRunningTasks(taskId) {
      const [error, data] = await LRTService.getLongRunningTasks(false, {
        name: actions.updateTask,
        status: statuses.started,
        task: taskId,
      });

      if (error) {
        console.log({ error });
        this.clearLRTInterval();
        return;
      }

      if (data.length) {
        this.isTaskBeingUpdated = true;
        this.showTaskUpdateNotification();
      } else {
        this.clearLRTInterval();
      }
    },

    showTaskUpdateNotification() {
      if (this.taskUpdateNotification) return;
      this.taskUpdateNotification = true;
      showNotification(
        'Updating Task & Meta Files',
        'We are updating your task & meta files, This will take few seconds.',
        InfoCircleOutlined,
        '#108ee9',
        0
      );
    },

    clearLRTInterval() {
      clearInterval(this.longRunningTaskInterval);
      this.taskUpdateNotification = false;
      this.isTaskBeingUpdated = false;
      notification.destroy();
    },
  },
};
