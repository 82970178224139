import ApiHelper from './index';

const getLongRunningTasks = async (spinner = true, queryParams = {}) => {
  return await ApiHelper.get(
    'generic/long_running_task/',
    spinner,
    queryParams
  );
};

const createLongRunningTasks = async (payload, spinner = false) => {
  return await ApiHelper.post('generic/long_running_task/', payload, spinner);
};

const getLongRunningTaskById = async (id, spinner = true, queryParams = {}) => {
  return await ApiHelper.get(
    `generic/long_running_task/${id}/`,
    spinner,
    queryParams
  );
};

const deleteLongRunningTask = async (id, spinner = false, queryParams = {}) => {
  return await ApiHelper.remove(
    `generic/long_running_task/${id}/`,
    spinner,
    queryParams
  );
};

export default {
  getLongRunningTasks,
  createLongRunningTasks,
  getLongRunningTaskById,
  deleteLongRunningTask,
};
